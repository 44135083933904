import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IRoomParams } from 'interfaces';
import { authSelector } from 'store/slices/auth';
import { clansSelector } from 'store/slices/clan';
import { roomActions, roomSelector } from 'store/slices/room';
import { getAllRoomsThunk } from 'store/slices/room/actions';
import { rolesValuesMap } from 'types/player';

import { setHiddenRoomsInSearchParams } from 'utils';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isFullscreenOpen: boolean;
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = ({
  isFullscreenOpen,
  onBack,
}) => {
  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);

  const { clans } = useAppSelector(clansSelector);

  const { id: accountId, roles } = useAppSelector(authSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const rooms = useMemo(
    () =>
      treeRooms.reduce((acc: IRoomParams[], data) => {
        if (data.rooms) {
          const convertedRooms = data.rooms.map((room) => ({
            ...room,
            clanName: data.clanName,
            clanId: data.clanId,
          }));

          acc = [...acc, ...convertedRooms];
        }

        return acc;
      }, []),
    [treeRooms]
  );

  const filteredRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.accountId === accountId ||
          roles.some(
            (role) =>
              role.clan_id === room.clanId &&
              role.role_id !== rolesValuesMap.user
          )
      ),
    [rooms, accountId]
  );

  useEffect(() => {
    const getRooms = () => {
      if (clans) {
        dispatch(getAllRoomsThunk());
      }
    };

    getRooms();

    const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, [clans]);

  useEffect(() => {
    dispatch(roomActions.setTreeRooms({ clans, searchParams }));
  }, [roomsData, clans]);

  useEffect(() => {
    if (!searchParams.size) {
      const params = setHiddenRoomsInSearchParams(searchParams, treeRooms);
      setSearchParams(params);
    }
  }, [treeRooms, searchParams]);

  console.log(
    'Active rooms :>>',
    filteredRooms.map(({ livekitName }) => livekitName)
  );

  return (
    <Panel
      isFullscreenOpen={isFullscreenOpen}
      onBack={onBack}
      rooms={filteredRooms}
    />
  );
};
